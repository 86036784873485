.App {
  text-align: center;
  overflow: hidden;
  position: relative;
  background-color: #282c34;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  padding: 5vh;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  font-family: monospace;
  font-size: calc(12px + 3vmin);
  color: white;
  
}

.App-link {
  color: white;
}
